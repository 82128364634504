import prize1 from "../assets/images/prizes/prize1.png";
import prize2 from "../assets/images/prizes/prize2.png";
import prize3 from "../assets/images/prizes/prize3.png";
import prize4 from "../assets/images/prizes/prize4.png";
import prize5 from "../assets/images/prizes/prize5.png";
import prize6 from "../assets/images/prizes/prize6.png";
import prize7 from "../assets/images/prizes/prize7.png";
import prize8 from "../assets/images/prizes/prize8.png";
import prize9 from "../assets/images/prizes/prize9.png";
import prize10 from "../assets/images/prizes/prize10.png";
import prize11 from "../assets/images/prizes/prize11.png";
import prize12 from "../assets/images/prizes/prize12.png";
export const prizeImages = [
  prize1,
  prize2,
  prize3,
  prize4,
  prize5,
  prize6,
  prize7,
  prize8,
  prize9,
  prize10,
  prize11,
  prize12,
];
