import { createContext, useEffect, useState } from "react";

export const LoadingContext = createContext();

const LoadingContextProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    window.scrollTo(0, document.getElementById("main").offsetTop);
  }, [loading]);

  return (
    <LoadingContext.Provider value={{ loading, setLoading }}>
      {children}
    </LoadingContext.Provider>
  );
};

export default LoadingContextProvider;
