import React, { useContext, useEffect } from "react";
import TitleSlug from "../../components/TitleSlug/TitleSlug";
import lifestyle from "../../assets/images/lifestyle.jpg";
import { LoadingContext } from "../../context/LoadingContext";
import { useLocation, useNavigate } from "react-router-dom";

const ClaimSuccess = () => {
  const { setLoading } = useContext(LoadingContext);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location?.state?.redirected) {
      setLoading(false);
    } else {
      navigate("/");
    }
  }, []);

  return (
    <div>
      <TitleSlug title={"THANKS"}>
        <p className="simple-text mt-4">
          Sorted! Thanks for submitting your details.
        </p>
        <p className="simple-text mt-4">
          Your prize will be on its way once your pint upload has been validated.
          Validations will take place within 14 days.
        </p>
        <p className="simple-text mt-4">We hope you enjoy your prize.</p>
        <h3 className="mt-4 font-bold text-3xl leading-9 text-brixtonDarkBlue font-geoGrotesque">
          BRIXTON BREWERY
        </h3>
      </TitleSlug>
      <div className="content with-background-content ">
        <img src={lifestyle} className="z-30 mobile-only" alt="Life Style" />
      </div>
    </div>
  );
};

export default ClaimSuccess;
