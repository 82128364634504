import React, { useContext, useEffect, useState } from "react";
import TitleSlug from "../../components/TitleSlug/TitleSlug";
import lifestyle from "../../assets/images/lifestyle.jpg";
import { LoadingContext } from "../../context/LoadingContext";
import { useLocation, useNavigate } from "react-router-dom";

const Closed = () => {
  const { setLoading } = useContext(LoadingContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [data, setData] = useState();
  useEffect(() => {
    if (location?.state?.redirected && location?.state?.data) {
      setData(location?.state?.data);
      setLoading(false);
    } else {
      navigate("/");
    }
  }, []);

  return (
    <div className="bg-brixtonGreen">
      <TitleSlug title={data?.title}>
        <p className="mt-4">{data?.message}</p>
      </TitleSlug>
      <div className="content with-background-content ">
        <img src={lifestyle} className="z-30 mobile-only" alt="Life Style" />
      </div>
    </div>
  );
};

export default Closed;
