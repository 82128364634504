import React, { useContext, useEffect } from "react";
import TitleSlug from "../../components/TitleSlug/TitleSlug";
import { LoadingContext } from "../../context/LoadingContext";

const CookiePolicy = () => {
  const { setLoading } = useContext(LoadingContext);
  useEffect(() => {
    setLoading(false);
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0 });
    (function (d, s, id) {
      var js,
        tjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s);
      js.id = id;
      js.src = "https://app.termly.io/embed-policy.min.js";
      tjs.parentNode.insertBefore(js, tjs);
    })(document, "script", "termly-jssdk");
  }, []);

  return (
    <div>
      <TitleSlug title={"Cookie Policy"}></TitleSlug>

                     
      <div
        name="termly-embed"
        data-id="5578d66e-3456-418f-864f-83ae634687e2"
        data-type="iframe"
        className="content text-left w-full mx-0 px-0 termly-custom-content"
      >
     
      </div>
      <div style={{         marginBottom: '30px',
                            textAlign: 'center',
                        }}>
                            <button
                                className="termly-cookie-preference-button"
                                type="button"
                                style={{
                                    background: 'white',
                                    width: '165px',
                                    height: '30px',
                                    borderRadius: '3px',
                                    border: '1px solid #5f7d9c',
                                    fontFamily: 'Roboto, Arial',
                                    fontSize: '10px',
                                    color: '#5f7d9c',
                                    fontWeight: '600',
                                    boxSizing: 'border-box',
                                    cursor: 'pointer',
                                    padding: 0,
                                    outline: 'none',
                                }}
                                onClick={() => { window.displayPreferenceModal() }}

                            >
                                Manage Cookie Preferences
                            </button>
                        </div>
    </div>
  );
};

export default CookiePolicy;
