import React, { useContext, useEffect, useState } from "react";
import TitleSlug from "../../components/TitleSlug/TitleSlug";
import { LoadingContext } from "../../context/LoadingContext";
import ApiService from "../../services/ApiService";
import { errorHandler } from "../../helpers/errorHelper";
import { useNavigate, useParams } from "react-router-dom";

const ClaimPrize = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { setLoading } = useContext(LoadingContext);
  const [hasErrors, setHasErrors] = useState(false);
  const [user, setUser] = useState(null);

  useEffect(() => {
    ApiService.checkUser(params.id).then((res) => {
      if (res?.result && res?.data && res?.data?.winner) {
        setUser({ ...res?.data, address: {} });
        setLoading(false);
      } else {
        errorHandler(res, navigate);
      }
    });
  }, []);

  const onPhoneNumberChange = (e) => {
    const input = e.target.value;
    const cleanedInput = input.replace(/[^0-9()+]/g, "");
    setUser({ ...user, phoneNumber: cleanedInput });
  };

  const validateForm = () => {
    const address = user?.address;
    const sizeIsRequired = user?._prize?.sizePreference ? true : false;
    if (
      !address?.addressLine1 ||
      !address?.cityTown ||
      !address?.postCode ||
      !address?.country ||
      !user?.phoneNumber ||
      (sizeIsRequired && !user?.prizeSize)
    ) {
      setHasErrors(true);
      return false;
    } else {
      return true;
    }
  };

  const onInputChange = (e) => {
    const data = { ...user };
    const { name, value } = e.target;
    data.address[name] = value;
    setUser(data);
  };

  const onSizeChange = (e) => {
    const data = { ...user };
    const { value } = e.target;
    data.prizeSize = value;
    setUser(data);
  };

  const submit = async () => {
    const validationResult = validateForm();
    if (validationResult) {
      setLoading(true);
      const userResponse = await ApiService.claim(user);
      if (userResponse && userResponse.result) {
        navigate("/thanks", {
          state: {
            redirected: true,
          },
        });
      } else {
        errorHandler(userResponse, navigate);
      }
    }
  };

  return (
    <div>
      <TitleSlug>
        <h1 className="congrats-title">CONGRATULATIONS!</h1>
        <h3 className="mt-6 font-bold text-3xl leading-9 text-brixtonDarkBlue font-geoGrotesque">
          YOU'VE WON A:
        </h3>

        <img
          src={user?._prize?.image}
          className="mx-auto mt-8 mb-6 max-w-[400px] w-full"
          alt="Prize"
        />

        {user?._prize?.isDigitalPrize && (
          <p className="mt-4 text-center simple-text">
            Thanks for taking part. If your entry has been validated, you will
            receive instructions on how to claim your prize within 14 days{" "}
          </p>
        )}

        {!user?._prize.isDigitalPrize && (
          <>
            <p className="mt-4 text-center simple-text">
              Enter your contact details below to claim your prize.
            </p>

            {/* <p className="mb-4 mt-4 text-center simple-text">
              If your entry has been validated, you will receive instructions on
              how to claim your prize within 14 days.
            </p> */}
          </>
        )}
      </TitleSlug>
      <div className="content ">
        {!user?._prize?.isDigitalPrize && (
          <>
            <input
              type="text"
              placeholder="FIRST NAME"
              name="firstName"
              className="brixton-input"
              defaultValue={user?.firstName}
              readOnly
            />

            <input
              type="text"
              placeholder="LAST NAME"
              name="lastName"
              className="brixton-input"
              defaultValue={user?.lastName}
              readOnly
            />

            <input
              type="text"
              placeholder="ADDRESS LINE 1"
              name="addressLine1"
              className="brixton-input"
              onChange={onInputChange}
            />

            <input
              type="text"
              placeholder="ADDRESS LINE 2"
              name="addressLine2"
              className="brixton-input"
              onChange={onInputChange}
            />

            {hasErrors &&
              !user?.address?.addressLine1 &&
              !user?.address?.addressLine2 && (
                <p className="input-field-error">Enter your address</p>
              )}

            <input
              type="text"
              placeholder="TOWN/CITY"
              name="cityTown"
              className="brixton-input"
              onChange={onInputChange}
            />

            {hasErrors && !user?.address?.cityTown && (
              <p className="input-field-error">Enter your town/city</p>
            )}

            <input
              type="text"
              placeholder="POSTCODE"
              name="postCode"
              className="brixton-input"
              onChange={onInputChange}
            />
            {hasErrors && !user?.address?.postCode && (
              <p className="input-field-error">Enter your post code</p>
            )}
            <select
              name="country"
              className="brixton-input select-input"
              onChange={onInputChange}
            >
              <option value={""}>COUNTRY</option>
              <option value={"England"}>ENGLAND</option>
              <option value={"Wales"}>WALES</option>
              <option value={"Scotland"}>SCOTLAND</option>
            </select>
            {hasErrors && !user?.address?.country && (
              <p className="input-field-error">Enter your country</p>
            )}

            <input
              type="text"
              placeholder="CONTACT NUMBER"
              name="phoneNumber"
              defaultValue={""}
              value={user?.phoneNumber}
              className="brixton-input"
              onChange={onPhoneNumberChange}
              pattern="[0-9()+]"
            />
            {hasErrors && !user?.phoneNumber && (
              <p className="input-field-error">Enter your contact number</p>
            )}

            {user?._prize?.sizePreference && (
              <>
                <select
                  name="size"
                  className="brixton-input select-input"
                  onChange={onSizeChange}
                >
                  <option value={""}>SIZE PREFERENCE</option>
                  <option value={"S"}>S</option>
                  <option value={"M"}>M</option>
                  <option value={"L"}>L</option>
                  <option value={"XL"}>XL</option>
                  <option value={"XXL"}>XXL</option>
                </select>
                {hasErrors && !user?.prizeSize && (
                  <p className="input-field-error">
                    Enter your size preference
                  </p>
                )}
                <p className="input-field-error">*Subject to availability. </p>
              </>
            )}

            <button className="brixton-primary-btn mb-6 mt-8" onClick={submit}>
              CLAIM YOUR PRIZE
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default ClaimPrize;
