import React, { useContext, useEffect } from "react";
import TitleSlug from "../../components/TitleSlug/TitleSlug";
import lifestyle from "../../assets/images/lifestyle.jpg";
import { LoadingContext } from "../../context/LoadingContext";

const SignupSuccess = () => {
  const { setLoading } = useContext(LoadingContext);

  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <div>
      <TitleSlug title={"NICE ONE!"}>
        <p className="simple-text mt-4">
          We’ll send an email to your inbox shortly.
        </p>
        <p className="simple-text mt-4">
          Please follow the link to activate your entry.
        </p>
        <p className="simple-text mt-4">
          If you don’t see the email, check your junk folder.
        </p>
      </TitleSlug>
      <div className="content with-background-content ">
        <img src={lifestyle} className="z-30 mobile-only" alt="Life Style" />
      </div>
    </div>
  );
};

export default SignupSuccess;
