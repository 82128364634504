import React, { useEffect } from "react";
import "./Footer.css";
import getATaste from "../../assets/images/getATaste.png";
import vinyl from "../../assets/images/VinylBox.svg";
import stack from "../../assets/images/Stack.svg";
import inPartnershipWith from "../../assets/images/inPartnerShipWith.svg";
import enjoyBrixtonResponsibly from "../../assets/images/enjoyBrixtonResponsibly.svg";
import drinkAware from "../../assets/images/drinkAware.svg";
import footerDrinks from "../../assets/images/footerDrinks.png";

const Footer = () => {
  useEffect(() => {
    (function (d, s, id) {
      var js,
        tjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s);
      js.id = id;
      js.src = "https://app.termly.io/embed-policy.min.js";
      tjs.parentNode.insertBefore(js, tjs);
    })(document, "script", "termly-jssdk");
  }, []);
  return (
    <footer className="footer-wrapper" id="footer-wrapper">
      <img src={footerDrinks} className="footer-drinks " alt="Mobile" />
      <div className="footer-content-wrapper">
        <div className="footer-content">
          <a
            href="https://brixtonbrewery.com/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={getATaste} className="get-a-taste" alt="Get a Taste" />
          </a>
          <hr className="mt-2 border-t-brixtonDarkBlue border-t-[2px]" />
          <img src={inPartnershipWith} className="mt-2" alt="Partnership" />
          <div className="flex flex-row w-full items-center mt-2">
            <a
              href="https://vinylbox.co/"
              target="_blank"
              className="w-[27%]"
              rel="noreferrer"
            >
              <img src={vinyl} className="w-full" alt="Partnership" />
            </a>
            <a
              href="https://www.stackmagazines.com/"
              target="_blank"
              rel="noreferrer"
              className="w-[73%]"
            >
              <img src={stack} className="w-full" alt="Partnership" />
            </a>
          </div>
        </div>
        <div className="footer-aware-responsibly">
          <img src={enjoyBrixtonResponsibly} alt="Partnership" />
          <img src={drinkAware} alt="Partnership" />
        </div>
      </div>
      <div className="terms-copyright ">
        <div>
          <p className="mb-2 lg:mb-0">
            <a
              href="https://brixtonbrewery.com/pages/privacy-policy"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>{" "}
            |{" "}
            <a href="/terms" target="_blank" rel="noreferrer">
              Terms & Conditions
            </a>{" "}
            |{" "}
            <a href="https://brixtonbrewery.com/pages/terms-of-use" target="_blank" rel="noreferrer">
              Terms of Use
            </a>{" "}
            |{" "}
            <a href="/cookie-policy" target="_blank" rel="noreferrer">
              Cookie Policy
            </a>{" "}
            |{" "}
            <label
              className="cursor-pointer"
              onClick={() => {
                window.displayPreferenceModal();
              }}
            >
              Cookie Preferences
            </label>
          </p>
          <p className="brixton-rights">© 2023 Brixton Brewery</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
