import React, { useContext, useEffect, useState } from "react";
import TitleSlug from "../../components/TitleSlug/TitleSlug";
import uploadIcon from "../../assets/images/uploadIcon.svg";
import removeIcon from "../../assets/images/removeIcon.svg";
import { LoadingContext } from "../../context/LoadingContext";
import ApiService from "../../services/ApiService";
import { errorHandler } from "../../helpers/errorHelper";
import { useNavigate } from "react-router-dom";
import MarketingModal from "../../components/Modals/MarketingModal";
import ReCAPTCHA from "react-google-recaptcha";

const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

const Signup = () => {
  const navigate = useNavigate();
  const { setLoading } = useContext(LoadingContext);
  const [hasErrors, setHasErrors] = useState(false);
  const [emailValid, setEmailValid] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [showOffersModal, setShowOffersModal] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState(null);

  const [user, setUser] = useState({
    firstName: "",
    lastName: "",
    email: "",
    venue: "",
    image: "",
    terms: false,
    offers: false,
  });

  useEffect(() => {
    setLoading(false);
  }, []);

  const validateForm = () => {
    if (
      !user?.firstName ||
      !user?.lastName ||
      !user?.email ||
      !user?.image ||
      !user?.venue ||
      !user?.terms ||
      !emailValid ||
      !recaptchaToken
    ) {
      setHasErrors(true);
      return false;
    } else {
      return true;
    }
  };

  const validateEmail = (email) => {
    // eslint-disable-next-line no-useless-escape
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      setEmailValid(true);
    } else {
      setEmailValid(false);
    }
  };

  const onInputChange = (e) => {
    const data = { ...user };
    const { name, value } = e.target;
    data[name] = value;
    setUser(data);
  };

  const removeUploaded = () => {
    setUser({ ...user, image: "" });
  };

  const onFileUpload = (e) => {
    const file = e.target.files[0];
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = async function () {
      setUploading(true);
      const fileObject = {
        name: file.name,
        mimeType: file.type,
        fileContent: reader.result.split(",")[1],
      };
      const response = await ApiService.uploadImage(fileObject);
      if (response && response?.data) {
        setUser({ ...user, image: response?.data });
      }
      setUploading(false);
    };
  };

  const submit = async () => {
    const validationResult = validateForm();
    if (validationResult) {
      setLoading(true);
      const userResponse = await ApiService.register({
        ...user,
        recaptchaToken: recaptchaToken,
      });
      if (userResponse && userResponse.result) {
        navigate("/success");
      } else {
        errorHandler(userResponse, navigate);
      }
    }
  };

  return (
    <div>
      <TitleSlug title={"ENTER TO WIN COOL STUFF"}>
        <p className="mb-4 mt-4 text-center simple-text">
          Fill out your details below and upload a picture of your Brixton
          Brewery beer to take part.
        </p>
      </TitleSlug>
      <div className="content">
        <input
          type="text"
          placeholder="FIRST NAME"
          name="firstName"
          className="brixton-input"
          onChange={onInputChange}
        />
        {hasErrors && !user?.firstName && (
          <p className="input-field-error">Enter your first name</p>
        )}
        <input
          type="text"
          placeholder="LAST NAME"
          name="lastName"
          className="brixton-input"
          onChange={onInputChange}
        />
        {hasErrors && !user?.lastName && (
          <p className="input-field-error">Enter your last name</p>
        )}
        <input
          type="text"
          placeholder="EMAIL"
          name="email"
          className="brixton-input"
          onChange={(event) => {
            onInputChange(event);
            validateEmail(event.target.value);
          }}
        />
        {hasErrors && !user?.email && (
          <p className="input-field-error">Enter your email</p>
        )}
        {hasErrors && !emailValid && user?.email && (
          <p className="input-field-error">Enter a valid email</p>
        )}

        <input
          type="text"
          placeholder="NAME OF VENUE"
          name="venue"
          className="brixton-input"
          onChange={onInputChange}
        />
        {hasErrors && !user?.venue && (
          <p className="input-field-error">Enter your name of venue</p>
        )}

        {!user?.image && (
          <label className="upload-box">
            <input
              type="file"
              accept="image/*"
              disabled={uploading}
              hidden
              onChange={onFileUpload}
            />
            <p className="simple-text">Upload an image of your Brixton pint</p>
            <div className="brixton-secondary-btn text-brixtonDarkBlue flex flex-row justify-center mt-3">
              {!uploading && (
                <>
                  UPLOAD IMAGE <img src={uploadIcon} alt="Upload" />
                </>
              )}
              {uploading && <div className="loading-spinner !w-6 !h-6" />}
            </div>
            {hasErrors && !user?.image && (
              <p className="input-field-error !mt-2 !text-center">
                Upload image of your Brixton pint
              </p>
            )}
          </label>
        )}

        {user?.image && (
          <div className="uploaded-image">
            <div className="uploaded-frame"></div>
            <input
              type="image"
              src={removeIcon}
              className="remove-uploaded"
              alt="uploaded"
              onClick={removeUploaded}
            />
            <img src={user?.image} alt="uploaded" />
          </div>
        )}

        <label className="checkbox-label ">
          <div
            className={`w-5 h-5 min-w-[20px] min-h-[20px] border-[2px] border-brixtonDarkBlue rounded ${
              user?.terms ? "bg-darkGreen" : ""
            }`}
            style={{ padding: user?.terms ? "2px" : "0" }}
          >
            {user?.terms && (
              <div className="w-full h-full bg-brixtonDarkBlue"></div>
            )}
          </div>
          <p className="text-darkBlue w-[calc(100%-1.25rem)] text-base text-left leading-6 ">
            I have read and agree to the{" "}
            <a
              className="underline"
              target="_blank"
              rel="noreferrer"
              href={"/terms"}
            >
              Terms & Conditions
            </a>
            .
          </p>

          <input
            type="checkbox"
            className="hidden"
            checked={user?.terms}
            onChange={(e) => {
              setUser({ ...user, terms: e.target.checked });
            }}
          />
        </label>
        {hasErrors && !user?.terms && (
          <p className="input-field-error mb-4">
            You must agree with our terms & conditions.
          </p>
        )}

        <label className="checkbox-label">
          <div
            className={`w-5 h-5 min-w-[20px] min-h-[20px] border-[2px] border-brixtonDarkBlue rounded ${
              user?.terms ? "bg-darkGreen" : ""
            }`}
            style={{ padding: user?.offers ? "2px" : "0" }}
          >
            {user?.offers && (
              <div className="w-full h-full bg-brixtonDarkBlue"></div>
            )}
          </div>
          <p className="text-darkBlue w-[calc(100%-1.25rem)] text-base text-left leading-6 ">
            Tick to receive personalised offers, promotions and news about our
            brands, pubs and special events via email and online targeted
            adverts. Brixton Brewery Limited will process your data accordance
            with our{" "}
            <a
              href="https://brixtonbrewery.com/pages/privacy-policy"
              target="_blank"
              rel="noreferrer"
              className="!underline"
            >
              Privacy Policy
            </a>
            . You can withdraw consent by clicking unsubscribe in any email or
            emailing{" "}
            <a
              href="mailto:protectyourdata@brixtonbrewery.com"
              className="!underline"
            >
              protectyourdata@brixtonbrewery.com
            </a>
            .
          </p>
          <input
            type="checkbox"
            className="hidden"
            checked={user?.offers}
            onChange={(e) => {
              setUser({ ...user, offers: e.target.checked });
            }}
          />
        </label>

        <div
          className={`${hasErrors && !recaptchaToken ? "recaptcha-error" : ""}`}
        >
          <ReCAPTCHA
            sitekey={RECAPTCHA_SITE_KEY}
            className="mb-4"
            onChange={setRecaptchaToken}
            size="normal"
            type="image"
          />

          {hasErrors && !recaptchaToken && (
            <p className="input-field-error recaptcha-error-message mt-4 mb-4">
              Please verify that you are not a robot.
            </p>
          )}
        </div>

        <button className="brixton-primary-btn mt-8" onClick={submit}>
          ENTER NOW
        </button>
      </div>
      {showOffersModal && (
        <MarketingModal
          showModal={showOffersModal}
          closeModal={() => {
            setShowOffersModal(false);
          }}
        />
      )}
    </div>
  );
};

export default Signup;
