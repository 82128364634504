import React, { useContext, useEffect } from "react";
import TitleSlug from "../../components/TitleSlug/TitleSlug";
import { Link, useNavigate } from "react-router-dom";
import { LoadingContext } from "../../context/LoadingContext";
import ApiService from "../../services/ApiService";
import { errorHandler } from "../../helpers/errorHelper";

const Landing = () => {
  const navigate = useNavigate();
  const { setLoading } = useContext(LoadingContext);

  useEffect(() => {
    setLoading(false);
  }, []);

  const checkCampaign = async () => {
    setLoading(true);
    const response = await ApiService.checkCampaign();
    if (response && response?.result && response?.data) {
      navigate("/signup");
    } else {
      errorHandler(response, navigate);
    }
  };

  const goToPrizes = () => {
    setLoading(true);
    navigate("/prizes");
  };

  return (
    <>
      <TitleSlug title={"WIN COOL STUFF"}>
        <p className="mt-4 text-center simple-text">
          Grab yourself a pint of Brixton & be in with a chance to win
          incredible prizes celebrating culture and creativity, like a vinyl
          player and a years' supply of expertly curated records, or an annual
          subscription of indie mags delivered right to your door.
        </p>
      </TitleSlug>
      <div className="content ">
        <button className="brixton-primary-btn mb-6" onClick={checkCampaign}>
          ENTER NOW
        </button>
        <button onClick={goToPrizes} className="brixton-secondary-btn ">
          THE PRIZES
        </button>
      </div>
    </>
  );
};

export default Landing;
