import React, { useContext } from "react";
import "./Layout.css";
import bgTexture from "../../assets/images/backgroundTexture.png";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { Outlet } from "react-router-dom";
import { LoadingContext } from "../../context/LoadingContext";
import contentLightening from "../../assets/images/contentLightening.png";
import topDesktop from "../../assets/images/desktop/topDesktop.png";

import topLeft from "../../assets/images/desktop/topLeft.png";
import bottomLeft from "../../assets/images/desktop/bottomLeft.png";
import middleLeft from "../../assets/images/desktop/middleLeft.png";
import topRight from "../../assets/images/desktop/topRight.png";
import bottomRight from "../../assets/images/desktop/bottomRight.png";

const Layout = () => {
  const { loading } = useContext(LoadingContext);

  return (
    <main id="main">
      <img src={bgTexture} className="bg-texture" alt="Background Texture" />
      <div className={`loading-wrapper ${!loading && "done"}`}>
        <div className="loading-spinner" />
      </div>
      <div className={`layout ${loading ? "hidden" : ""}`}>
        <div className="absolute-content mr-auto desktop-only lg:z-[40]">
          <img
            src={topLeft}
            draggable={false}
            className=" absolute min-w-[584px] min-h-[717px] -right-[10%] top-[-400px]"
            alt="Content"
          />

          <img
            src={middleLeft}
            draggable={false}
            className=" absolute  !-z-10 min-w-[740px] min-h-[699px] right-[20%] -top-[75px]"
            alt="Content"
          />
          <img
            src={bottomLeft}
            draggable={false}
            className=" absolute min-w-[594px] min-h-[691px] right-[0%] -bottom-[100px]"
            alt="Content"
          />
        </div>
        <div className="brixton-container">
          <img
            src={topDesktop}
            draggable={false}
            className=" absolute min-w-[652px] min-h-[534px] -top-[400px] left-[50%] right-[50%] desktop-only"
            style={{ transform: "translateX(-30%)" }}
            alt="Content"
          />
          <Header />
          <div className="main-content" id="main-content">
            <img
              src={contentLightening}
              draggable={false}
              className="lg:hidden absolute left-0 w-screen top-[50%] z-[10]"
              style={{ transform: "translate(0%,-50%)" }}
              alt="Content Lightenings"
            />
            <Outlet />
          </div>
          <Footer />
        </div>
        <div className="absolute-content ml-auto desktop-only lg:z-[40]">
          <img
            src={topRight}
            draggable={false}
            className=" absolute min-w-[928px] min-h-[1128px] left-[0px] top-[-200px]"
            alt="Content"
          />
          <img
            src={bottomRight}
            draggable={false}
            className=" absolute min-w-[595px] min-h-[855px] left-[0%] -bottom-[100px]"
            alt="Content"
          />
        </div>
      </div>
    </main>
  );
};

export default Layout;
