import { createBrowserRouter, redirect } from "react-router-dom";
import { routeNames } from "../constants/routeNames";
import Layout from "../components/Layout/Layout";
import Terms from "../containers/Simple/Terms";
import TermsOfUse from "../containers/Simple/TermsOfUse";
import Landing from "../containers/Landing/Landing";
import CookiePolicy from "../containers/Simple/CookiePolicy";
import Closed from "../containers/Simple/Closed";
import PreEntry from "../containers/Simple/PreEntry";
import OutOfHours from "../containers/Simple/OutOfHours";
import Prizes from "../containers/Simple/Prizes";
import Signup from "../containers/Signup/Signup";
import SignupSuccess from "../containers/Signup/SignupSuccess";
import NoWin from "../containers/Simple/NoWin";
import ClaimSuccess from "../containers/Claim/ClaimSuccess";
import AlreadyClaimed from "../containers/Simple/AlreadyClaimed";
import LinkExpired from "../containers/Simple/LinkExpired";
import Activate from "../containers/Activate/Activate";
import ClaimPrize from "../containers/Claim/ClaimPrize";
import AlreadyEntered from "../containers/Simple/AlreadyEntered";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        index: true,
        element: <Landing />,
        name: "Landing",
      },
      {
        path: routeNames.TERMS,
        element: <Terms />,
        name: "Terms And Conditions",
      },
      {
        path: routeNames.COOKIE_POLICY,
        element: <CookiePolicy />,
        name: "Cookie Policy",
      },
      {
        path: routeNames.CLOSED,
        element: <Closed />,
        name: "Closed",
      },
      {
        path: routeNames.PRE_ENTRY,
        element: <PreEntry />,
        name: "Pre Entry",
      },
      {
        path: routeNames.OUT_OF_HOURS,
        element: <OutOfHours />,
        name: "Out of Hours",
      },
      {
        path: routeNames.PRIZES,
        element: <Prizes />,
        name: "Prizes",
      },
      {
        path: routeNames.SIGNUP,
        element: <Signup />,
        name: "Signup",
      },
      {
        path: routeNames.SIGNUP_SUCCESS,
        element: <SignupSuccess />,
        name: "Signup Success",
      },
      {
        path: routeNames.NOT_WINNER,
        element: <NoWin />,
        name: "Now Winner",
      },
      {
        path: routeNames.ALREADY_CLAIMED,
        element: <AlreadyClaimed />,
        name: "Already Claimed",
      },
      {
        path: routeNames.CLAIM_PRIZE,
        element: <ClaimPrize />,
        name: "Claim Prize",
      },
      {
        path: routeNames.CLAIM_SUCCESS,
        element: <ClaimSuccess />,
        name: "Claim Success",
      },
      {
        path: routeNames.LINK_EXPIRED,
        element: <LinkExpired />,
        name: "Link Expired",
      },
      {
        path: routeNames.ACTIVATE,
        element: <Activate />,
        name: "Activate Entry",
      },
      {
        path: routeNames.ALREADY_ENTERED,
        element: <AlreadyEntered />,
        name: "Already Entered",
      },
    ],
  },
  {
    path: "*",
    loader: () => {
      return redirect("/");
    },
  },
]);
