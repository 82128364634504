import React, { useContext, useEffect, useState } from "react";
import TitleSlug from "../../components/TitleSlug/TitleSlug";
import lifestyle from "../../assets/images/lifestyle.jpg";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { LoadingContext } from "../../context/LoadingContext";

const PreEntry = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [date, setDate] = useState(null);
  const { setLoading } = useContext(LoadingContext);

  useEffect(() => {
    if (location?.state?.redirected && location?.state?.data) {
      setLoading(false);
      setDate(location?.state?.data?.date);
    } else {
      navigate("/");
    }
  }, []);

  return (
    <div className="bg-brixtonGreen">
      <TitleSlug title={"SORRY"}>
        <p className="mt-4 simple-text">The promotion hasn't started yet.</p>
        <p className="mt-4 simple-text">
          Promotion starts {date && moment.utc(date).format("DD/MM/YY")}.
        </p>
      </TitleSlug>
      <div className="content with-background-content">
        <img src={lifestyle} className="z-30 mobile-only" alt="Life Style" />
      </div>
    </div>
  );
};

export default PreEntry;
