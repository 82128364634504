import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";

export default function MarketingModal({ showModal, closeModal }) {
  return (
    <Transition.Root show={showModal} as={Fragment}>
      <Dialog as="div" className="relative z-[1000]" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-[#0C212D] bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden  bg-brixtonGreen text-left  transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-brixtonGreen p-5">
                  <div className="sm:flex sm:items-start">
                    <div className="mt-3 text-center  sm:mt-0 sm:text-left">
                      {/* <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900"
                      >
                        Deactivate account
                      </Dialog.Title> */}
                      <div className="mt-2">
                        <p className="text-sm text-brixtonDarkBlue text-center">
                          Tick to receive personalised offers, promotions and
                          news about our brands, pubs and special events via
                          email and online targeted adverts. Brixton Brewery
                          Limited will process your data accordance with our{" "}
                          <a
                            href="https://brixtonbrewery.com/pages/privacy-policy"
                            target="_blank"
                            rel="noreferrer"
                            className="!underline"
                          >
                            Privacy Policy
                          </a>
                          . You can withdraw consent by clicking unsubscribe in
                          any email or emailing{" "}
                          <a href="mailto:protectyourdata@brixtonbrewery.com" className="!underline">
                            protectyourdata@brixtonbrewery.com
                          </a>
                          .
                          {/* Send me personalised offers and news about the Brixton
                          Brewery brand via email and online targeted adverts.
                          Brixton Brewery Limited will process your data in
                          accordance with our{" "}
                          <a
                            href="https://brixtonbrewery.com/pages/privacy-policy"
                            target="_blank"
                            rel="noreferrer"
                            className="!underline"
                          >
                            Privacy Policy
                          </a>
                          . You can withdraw consent by clicking unsubscribe in
                          any email or emailing{" "}
                          <a
                            className="underline"
                            href="mailto:protectyourdata@brixtonbrewery.com"
                          >
                            protectyourdata@brixtonbrewery.com
                          </a>
                          . */}
                        </p>
                        <button
                          onClick={closeModal}
                          className="brixton-primary-btn mt-8"
                        >
                          CLOSE
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
