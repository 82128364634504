import React from "react";
import "./TitleSlug.css";
const TitleSlug = ({ title, children }) => {
  return (
    <div className="title-slug-wrapper">
      <div className="title-slug ">
        <h1 className="title">{title}</h1>
        <div className="slug-children">{children}</div>
      </div>
    </div>
  );
};

export default TitleSlug;
