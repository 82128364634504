import React, { useContext, useEffect } from "react";
import TitleSlug from "../../components/TitleSlug/TitleSlug";
import { LoadingContext } from "../../context/LoadingContext";

const Terms = () => {
  const { setLoading } = useContext(LoadingContext);
  useEffect(() => {
    setLoading(false);
  }, []);
  return (
    <div>
      <TitleSlug title={"TERMS & CONDITIONS"}></TitleSlug>
      <div className="content terms-content">
        <p className="mb-4 simple-text font-bold ">
          Promoter: Brixton Brewery Limited, Units 1-2 Dylan Road, London SE24
          0HL part of Heineken UK Limited, 3-4 Broadway Park, South Gyle
          Broadway, EDINBURGH, EH12 9JZ
        </p>
        <p className="mb-4 simple-text">
          These Terms and Conditions prevail in the event of any conflict or
          inconsistency with any other communications, including advertising or
          promotional materials. Entry instructions are deemed to form part of
          the terms and conditions and by entering this promotion all
          participants will be deemed to have accepted and be bound by the terms
          and conditions. Please retain a copy for your information.
        </p>
        <p className="mb-4 simple-text">
          The personal data provided by entrants to the Promoter will only be
          used by the Promoter for the purpose of conducting the promotion and
          at all times in accordance with the Data Protection Act 2018 and the
          Promoter’s Privacy Policy (available at{" "}
          <a
            href="https://brixtonbrewery.com/pages/privacy-policy"
            target="_blank"
            className="!underline"
            rel="noreferrer"
          >
            https://brixtonbrewery.com/pages/privacy-policy
          </a>
          ). From time to time, the Promoter may share entrants’ personal data
          with their agents/representatives and Google to assist with
          administering the promotion, contacting entrants and prize fulfilment
          (as necessary).
        </p>
        <p className="simple-text mb-2 font-bold">1. HOW TO TAKE PART</p>
        <p className="simple-text mb-4">
          Only open to UK residents only aged 18 or over, excluding employees of
          any company in the Heineken group, Brixton Brewery Limited and any
          person whom, in the Promoter’s reasonable opinion, should be excluded
          due to their involvement or connection with this promotion.{" "}
        </p>
        <p className="simple-text mb-4">
          In order to take part in this promotion participants should follow the
          steps noted below:
        </p>
        <ol type="a" className="simple-text mb-4">
          <li>
            Purchase any Brixton Brewery product available on draught within a
            participating venue. Subject to availability. Service is at the
            discretion of the venue staff.
          </li>
          <li>
            Entrants must then scan the QR code on promotional comms in-venue or
            visit{" "}
            <a
              href="https://www.cultureontap.brixtonbrewery.com/"
              target="_blank"
              rel="noreferrer"
              className="!underline !break-all"
            >
              www.cultureontap.brixtonbrewery.com/
            </a>{" "}
            and complete the online entry process, uploading a photo of any
            Brixton Brewery product available on draught purchased in a
            participating venue. Standard network rates apply.
          </li>
          <li>
            Upon completing all steps, winners will be notified instantly via
            the promotional platform.
          </li>
          <li>
            Promotion opens 00.01 on 02.10.23 and closes 23.59 03.12.23 (the
            “Promotion Period”).
          </li>
          <li>Limited to one entry per person per day.</li>
        </ol>
        <p className="simple-text mb-2 font-bold">2. PRIZES</p>
        <p className="simple-text mb-4">
          The prizes available to be won are as follows:
        </p>
        <p className="simple-text mb-2 font-bold">MID TIER</p>
        <p className="simple-text mb-2">
          There are 120 mid tier prizes to be won from Brixton Brewery, Stack
          and VinylBox. The breakdown of prizes available to be won are shown in
          the table below.
        </p>
        <table className="mb-4">
          <tr>
            <td colspan="1" rowspan="1">
              <p>
                <span>Prize</span>
              </p>
            </td>
            <td colspan="1" rowspan="1">
              <p>
                <span>No. Prizes</span>
              </p>
            </td>
          </tr>
          <tr>
            <td colspan="1" rowspan="1">
              <p>
                <span>Brixton Brewery: Single A3 Art Print</span>
              </p>
            </td>
            <td colspan="1" rowspan="1">
              <p>
                <span>20</span>
              </p>
            </td>
          </tr>
          <tr>
            <td colspan="1" rowspan="1">
              <p>
                <span>Brixton Brewery: Baseball Cap</span>
              </p>
            </td>
            <td colspan="1" rowspan="1">
              <p>
                <span>20</span>
              </p>
            </td>
          </tr>
          <tr>
            <td colspan="1" rowspan="1">
              <p>
                <span>Brixton Brewery: Tote Bag</span>
              </p>
            </td>
            <td colspan="1" rowspan="1">
              <p>
                <span>20</span>
              </p>
            </td>
          </tr>
          <tr>
            <td colspan="1" rowspan="1">
              <p>
                <span>Brixton Brewery: Tshirts</span>
              </p>
              <p>
                <span>(S, M, L, XL, XXL)</span>
              </p>
            </td>
            <td colspan="1" rowspan="1">
              <p>
                <span>20</span>
              </p>
            </td>
          </tr>
          <tr>
            <td colspan="1" rowspan="1">
              <p>
                <span>
                  Stack: 3 Month Subscription (three magazines over the period
                  of 3 months)
                </span>
              </p>
            </td>
            <td colspan="1" rowspan="1">
              <p>
                <span>20</span>
              </p>
            </td>
          </tr>
          <tr>
            <td colspan="1" rowspan="1">
              <p>
                <span>
                  VinylBox: 1 month VinylBox Subscription (2 albums over the
                  period of 1 month)
                </span>
              </p>
            </td>
            <td colspan="1" rowspan="1">
              <p>
                <span>20</span>
              </p>
            </td>
          </tr>
        </table>
        <p className="simple-text mb-2 font-bold">TOP TIER</p>
        <p className="simple-text mb-2">
          There are 22 top tier prizes to be won from Brixton Brewery, Stack and
          VinylBox. The breakdown of prizes available to be won are shown in the
          table below.
        </p>
        <table className="mb-4">
          <tr>
            <td colspan="1" rowspan="1">
              <p>
                <span>Prize</span>
              </p>
            </td>
            <td colspan="1" rowspan="1">
              <p>
                <span>No. Prizes</span>
              </p>
            </td>
          </tr>
          <tr>
            <td colspan="1" rowspan="1">
              <p>
                <span>Brixton Brewery: M.C.Overalls Denim Work Jacket</span>
              </p>
              <p>
                <span>(x1 S, x2 M, x1 L)</span>
              </p>
            </td>
            <td colspan="1" rowspan="1">
              <p>
                <span>4</span>
              </p>
            </td>
          </tr>
          <tr>
            <td colspan="1" rowspan="1">
              <p>
                <span>Brixton Brewery: Neon Sign</span>
              </p>
              <p>
                <span>Depth: 30mm, Diameter: 600mm</span>
              </p>
            </td>
            <td colspan="1" rowspan="1">
              <p>
                <span>4</span>
              </p>
            </td>
          </tr>
          <tr>
            <td colspan="1" rowspan="1">
              <p>
                <span>Brixton Brewery: Set of 5 A3 Art Prints</span>
              </p>
            </td>
            <td colspan="1" rowspan="1">
              <p>
                <span>4</span>
              </p>
            </td>
          </tr>
          <tr>
            <td colspan="1" rowspan="1">
              <p>
                <span>Brixton Brewery: Navy Brixton Sweatshirt</span>
              </p>
              <p>
                <span>(S, M, L, XL, XXL)</span>
              </p>
            </td>
            <td colspan="1" rowspan="1">
              <p>
                <span>4</span>
              </p>
            </td>
          </tr>
          <tr>
            <td colspan="1" rowspan="1">
              <p>
                <span>
                  Stack: Year subscription with x1 magazine received monthly
                  (x12 in total)
                </span>
              </p>
            </td>
            <td colspan="1" rowspan="1">
              <p>
                <span>4</span>
              </p>
            </td>
          </tr>
          <tr>
            <td colspan="1" rowspan="1">
              <p>
                <span>
                  VinylBox: Year subscription (2 albums per month) &amp; Crosley
                  T150 record player
                </span>
              </p>
            </td>
            <td colspan="1" rowspan="1">
              <p>
                <span>2</span>
              </p>
            </td>
          </tr>
        </table>
        <p className="simple-text mb-4 font-bold">3. PRIZE ALLOCATION</p>
        <p className="simple-text mb-4">
          Winning moments mechanics – prizes have been allocated in advance at
          random throughout the Promotional Period throughout all participating
          venues via an independently audited and verified winning moments
          program. If the participant’s valid entry matches one of the winning
          moments distributed via the winning moment mechanic at the time of
          entry, the participant will win a prize. All prizes have been
          allocated at random and are as stipulated and will be available to be
          won. Only one item is available to be won per winning moment.
        </p>
        <p className="simple-text mb-4">
          Winners will receive instructions on how to claim their prize within
          14 days of receiving original win notice. If the winners do not
          respond to the winning message received within the digital entry
          platform and email confirmation within 7 working days, the Promoter
          reserves the right to select new winners who were the closest entry to
          the winning moment (and so on until winners can be contacted who will
          take up the prizes).
        </p>
        <p className="simple-text mb-4">
          All unclaimed prizes will be included in a mop up draw and will be
          allocated on a random basis to the individual(s) selected at random
          from all valid remaining entries. Re-selected winners will then be
          emailed within 7 days and have 7 days to acknowledge receipt (and
          confirm their details for delivery in respect of prizes). This process
          will be repeated until a winner is found for each unclaimed prize.
        </p>
        <p className="simple-text mb-4">
          Winners will be contacted by{" "}
          <a
            className="!underline"
            href="mailto:brixtonbrewery@meshmarketing.co.uk"
          >
            brixtonbrewery@meshmarketing.co.uk
          </a>{" "}
          on behalf of the Promoter.
        </p>
        <p className="simple-text mb-4 font-bold">3. PRIZE TERMS</p>
        <p className="simple-text mb-4">
          Only one prize total can be won per person and by household across the
          entire promotional period.
        </p>
        <p className="simple-text mb-4">
          Winners’ details (first name and county) will be available from{" "}
          <a
            className="!underline"
            href="mailto:brixtonbrewery@meshmarketing.co.uk"
          >
            brixtonbrewery@meshmarketing.co.uk
          </a>{" "}
          from 11.12.23 for 3 months. By entering, the winner consents to such
          information being disclosed upon request.
        </p>
        <p className="simple-text mb-4">
          Brixton Brewery Merchandise Prize Terms
        </p>
        <ol type="a" className="mb-4">
          <li>
            The Prizes available include a Brixton Brewery branded sweatshirt,
            neon sign, M.C.Overalls Denim Work Jacket, full set of 5 A3 art
            prints, single A3 art print, baseball cap, tote bag and tshirt. Only
            one item is available to be won per winning moment.
          </li>
        </ol>
        <p className="simple-text mb-4">Merchandise Redemption Instructions</p>
        <ol type="a" className="mb-4">
          <li>
            As part of winner validation as set out in Term 3 winners will be
            required to supply additional information used to fulfil the prize
            which includes address and a contact number.
          </li>
          <li>
            Once confirmed as a valid win, the prize claim will be processed and
            the prize dispatched to the winner’s given address.
          </li>
        </ol>

        <p className="simple-text mb-4">Stack Prize Terms</p>
        <ol type="a" className="mb-4">
          <li>
            The Prizes available include a 3 month Stack Magazine subscription
            and 1 year Stack magazine subscription. Only one item is available
            to be won per winning moment.
          </li>
        </ol>

        <p className="simple-text mb-4">Stack Redemption Instructions</p>
        <ol type="a" className="mb-4">
          <li>
            As part of the winner validation as set out in Term 3 winners will
            be required to supply additional information used to fulfil the
            prize which includes address and a contact number.
          </li>
          <li>
            Once confirmed as a valid win, the prize claim will be processed and
            the prize dispatched to the winners given address.
          </li>
          <li>
            Stack terms and conditions apply. Please see{" "}
            <a
              href="https://www.stackmagazines.com/terms-and-conditions/"
              target="_blank"
              rel="noreferrer"
              className="!underline !break-all"
            >
              https://www.stackmagazines.com/terms-and-conditions/
            </a>{" "}
            for further details.
          </li>
        </ol>
        <p className="simple-text mb-4">VinylBox Prize Terms</p>
        <p className="simple-text mb-4">
          {" "}
          The Prizes available include a 1 month subscription to VinylBox and 1
          year subscription to VinylBox and Crosly T150 record player. Only one
          item is available to be won per winning moment. Digital codes for 1
          month subscriptions are to be used within 2 years of receipt.
        </p>
        <p className="simple-text mb-4">VinylBox Redemption Instructions</p>
        <ol type="a" className="mb-4">
          <li>
            As part of the winner validation as set out in Term 3 winners will
            be required to supply additional information used to fulfil the
            prize which includes address and a contact number.
          </li>
          <li>
            Once confirmed as a valid win, the prize claim will be processed and
            the prize dispatched to the winners given address. Digital codes
            will be sent via email to the winners given email address at point
            of entry.
          </li>
          <li>
            Vinyl Box terms and conditions apply. Please see{" "}
            <a
              href="https://vinylbox.co/pages/store-policies"
              target="_blank"
              rel="noreferrer"
              className="!underline !break-all"
            >
              https://vinylbox.co/pages/store-policies
            </a>{" "}
            for further details.
          </li>
        </ol>
        <p className="simple-text mb-4 !text-center font-bold">
          TERMS APPLICABLE TO ALL ELEMENTS OF THE PROMOTION AND ALL PRIZES
        </p>
        <p className="simple-text mb-4">
          5. There are no cash or other alternatives to any of the prizes in
          whole or in part.
        </p>
        <p className="simple-text mb-4">6. Prizes are not transferable.</p>
        <p className="simple-text mb-4">
          7. The Promoter reserves the right to reclaim a prize if it is
          transferred.
        </p>
        <p className="simple-text mb-4">
          8. Entries must be made personally. Entries made through agents/third
          parties, bulk entries or automated entries are invalid.
        </p>
        <p className="simple-text mb-4">
          9. The Promoter accepts no responsibility for lost, damaged,
          incomplete, illegible or delayed uploaded entries to the website, such
          entries will be void. The Promoter does not accept any responsibility
          for the non-receipt or the late receipt of submitted entries due to
          network failure or for any associated costs to participants. Your
          standard network rates apply.
        </p>
        <p className="simple-text mb-4">
          10. No entries submitted via any other means than the means described
          in these terms and conditions will be accepted. The Promoter does not
          guarantee uninterrupted or secure access to the web entry route. Any
          mechanical reproduction or automated entries is prohibited, and any
          use of such automated devices will cause disqualification.
        </p>
        <p className="simple-text mb-4">
          11. The Promoter reserves the right to verify all entries and the
          winners and to refuse to award a prize or withdraw a prize entitlement
          and/or refuse further participation and disqualify the participant
          where there are reasonable grounds to believe there has been a breach
          of these terms and conditions or any instructions forming part of
          entry requirements or otherwise where a participant has gained unfair
          advantage in participating in the promotion or won using fraudulent
          means. The Promoter will be the final arbiter in any decisions and
          these will be binding and no correspondence will be entered into.
        </p>
        <p className="simple-text mb-4">
          12. The Promoter will not be liable for any reimbursement where a
          purchase has been made but the entry not submitted prior to the end of
          the promotion.
        </p>
        <p className="simple-text mb-4">
          13. The decision of the Promoter in all matters is final and binding
          and no correspondence will be entered into.
        </p>
        <p className="simple-text mb-4">
          14. The Promoter is not responsible for any third-party acts or
          omissions.
        </p>
        <p className="simple-text mb-4">
          15. The Promoter reserves the right to cancel or amend this promotion
          at any time due to circumstances beyond its control.
        </p>
        <p className="simple-text mb-4">
          16. All taxes (including without limitation national & local taxes) in
          connection with any prize and the reporting consequence thereof, are
          the sole responsibility of the prize winner.
        </p>
        <p className="simple-text mb-4">
          17. By submitting your photograph you are agreeing and confirming
          that:
        </p>
        <ol className="mb-4 !list-disc">
          <li>
            You shall be solely responsible for your entry and the consequences
            of posting it.
          </li>
          <li>
            The entire copyright in the submission and all content included in
            it of any nature, including images, script etc is original and
            belongs to you
          </li>
          <li>No people appear in the photograph;</li>
          <li>
            Your submission is not false, a misrepresentation, obscene,
            offensive, discriminatory or libelous;
          </li>
        </ol>
        <p className="simple-text mb-4">
          18. These rules and any dispute or difference arising out of or in
          connection with them shall be governed by and construed in accordance
          with English law. The Promoter and each entrant irrevocably agree to
          submit to the exclusive jurisdiction of the Courts of England and
          Wales over any claim or matter arising under or in connection with
          these rules or the legal relationships established by this agreement.
        </p>
        <p className="simple-text mb-4">
          19. For any consumer queries, please get in touch with{" "}
          <a href="mailto:consumercare@heineken.co.uk" className="!underline">
            consumercare@heineken.co.uk
          </a>
        </p>
      </div>
    </div>
  );
};

export default Terms;
