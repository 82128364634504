import React, { useContext, useEffect } from "react";
import TitleSlug from "../../components/TitleSlug/TitleSlug";
import lifestyle from "../../assets/images/lifestyle.jpg";
import { LoadingContext } from "../../context/LoadingContext";
import { useLocation, useNavigate } from "react-router-dom";

const LinkExpired = () => {
  const { setLoading } = useContext(LoadingContext);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location?.state?.redirected) {
      setLoading(false);
    } else {
      navigate("/");
    }
  }, []);

  return (
    <div>
      <TitleSlug title={"SORRY"}>
        <p className="simple-text mt-4">Your link has expired.</p>
        <p className="mt-4 simple-text">
          Please contact{" "}
          <a
            href="mailto:brixtonbrewery@meshmarketing.co.uk"
            className="underline"
          >
            brixtonbrewery@meshmarketing.co.uk
          </a>{" "}
          for more details.{" "}
        </p>
      </TitleSlug>
      <div className="content with-background-content ">
        <img src={lifestyle} className="z-30 mobile-only" alt="Life Style" />
      </div>
    </div>
  );
};

export default LinkExpired;
