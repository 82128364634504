const API_URL = process.env.REACT_APP_API_URL;
const baseApiUrl = `${API_URL}/api/entry`;

const Endpoints = {
  checkUser: `${baseApiUrl}`,
  checkCampaign: `${baseApiUrl}/check-campaign`,
  register: `${baseApiUrl}`,
  claim: `${baseApiUrl}/claim`,
  upload: `${baseApiUrl}/upload`,
  validate: `${baseApiUrl}/validate`,
};

export default Endpoints;
