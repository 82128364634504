import React from "react";
import "./Header.css";
import brixtonLogo from "../../assets/images/brixtonLogo.svg";
import cultureOnTap from "../../assets/images/cultureOnTap.png";
import headerDrinks from "../../assets/images/headerDrinks.png";

const Header = () => {
  return (
    <div className="header" id="header">
      <div className="green-box"></div>
      <img src={headerDrinks} alt="Header Mobile" className="header-mobile" />
      <a href="/">
        <img src={brixtonLogo} alt="Brixton" className="brixton-logo" />
      </a>
      <img src={cultureOnTap} alt="Culture on Tap" className="culture-on-tap" />
    </div>
  );
};

export default Header;
