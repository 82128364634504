import React, { useContext, useEffect, useState } from "react";
import TitleSlug from "../../components/TitleSlug/TitleSlug";
import lifestyle from "../../assets/images/lifestyle.jpg";
import { LoadingContext } from "../../context/LoadingContext";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";

const OutOfHours = () => {
  const { setLoading } = useContext(LoadingContext);
  const [startHour, setStartHour] = useState();
  const [endHour, setEndHour] = useState();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!location?.state?.redirected) {
      navigate("/");
    } else {
      const sHour = new Date();
      const eHour = new Date();
      sHour.setUTCHours(location?.state?.data?.startHour, 0, 0, 0);
      eHour.setUTCHours(location?.state?.data?.endHour, 0, 0, 0);
      setStartHour(sHour);
      setEndHour(eHour);
      setLoading(false);
    }
  }, []);

  return (
    <div className="bg-brixtonGreen">
      <TitleSlug title={"SORRY"}>
        <p className="mt-4 simple-text">
          This promotion is only open between{" "}
          {startHour && moment.utc(startHour).format("ha")} and{" "}
          {endHour && moment.utc(endHour).format("ha")}.{" "}
        </p>
        <p className="mt-4 simple-text">
          Please check back again during these hours.{" "}
        </p>
      </TitleSlug>
      <div className="content with-background-content ">
        <img src={lifestyle} className="z-30 mobile-only " alt="Life Style" />
      </div>
    </div>
  );
};

export default OutOfHours;
