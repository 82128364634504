import React, { useContext, useEffect } from "react";
import TitleSlug from "../../components/TitleSlug/TitleSlug";
import { prizeImages } from "../../constants/PrizeImages";
import { useNavigate } from "react-router-dom";
import { LoadingContext } from "../../context/LoadingContext";
const Prizes = () => {
  const { setLoading } = useContext(LoadingContext);
  const navigate = useNavigate();
  useEffect(() => {
    setLoading(false);
  }, []);

  const goBack = () => {
    setLoading(true);
    navigate("/");
  };

  return (
    <div>
      <TitleSlug title={"Prizes"}>
        <p className="simple-text mt-4">
          Scroll down to see all the prizes available to win.
        </p>
      </TitleSlug>
      <div className="content text-left ">
        {prizeImages.map((image, i) => {
          return (
            <img
              key={i}
              draggable={false}
              src={image}
              alt="Prize"
              className="w-full mb-8 max-w-[400px]"
            />
          );
        })}
        <button
          onClick={goBack}
          className="brixton-primary-btn mt-8 !max-w-[350px]"
        >
          BACK
        </button>
      </div>
    </div>
  );
};

export default Prizes;
