import React, { useContext, useEffect } from "react";
import TitleSlug from "../../components/TitleSlug/TitleSlug";
import lifestyle from "../../assets/images/lifestyle.jpg";
import { LoadingContext } from "../../context/LoadingContext";
import ApiService from "../../services/ApiService";
import { useNavigate, useParams } from "react-router-dom";
import { errorHandler } from "../../helpers/errorHelper";
import loadingAnimation from "../../assets/images/loadingAnimation.gif";

const Activate = () => {
  const { loading, setLoading } = useContext(LoadingContext);
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    const img = new Image();
    img.src = loadingAnimation;
    img.onload = () => {
      setLoading(false);
    };
    img.onerror = () => {
      setLoading(false);
    };
  }, []);

  useEffect(() => {
    if (!loading) {
      setTimeout(() => {
        ApiService.validate(params.id).then((res) => {
          if (res && res?.result && res?.data) {
            if (res?.data.winner) {
              setLoading(true);
              navigate(`/claim-prize/${params.id}`, {
                state: {
                  user: res?.data,
                  redirected: true,
                },
              });
            }
          } else {
            setLoading(true);
            errorHandler(res, navigate);
          }
        });
      }, 4000);
    }
  }, [loading]);

  return (
    <div>
      <TitleSlug title={"LOADING..."}></TitleSlug>
      <div className="content all-green-content ">
        <img src={loadingAnimation} className="z-30 mt-4 w-full max-w-[400px]" alt="Life Style" />
      </div>
    </div>
  );
};

export default Activate;
