export const routeNames = {
  LANDING: "/",
  TERMS: "/terms",
  TERMS_OF_USE: "/terms-of-use",
  COOKIE_POLICY: "/cookie-policy",
  PRIVACY_POLICY: "/privacy-policy",
  CLOSED: "/closed",
  PRE_ENTRY: "/pre-entry",
  OUT_OF_HOURS: "/out-of-hours",
  PRIZES: "/prizes",
  SIGNUP: "/signup",
  SIGNUP_SUCCESS: "/success",
  ALREADY_CLAIMED: "/already-claimed",
  NOT_WINNER: "/not-winner",
  CLAIM_PRIZE: "/claim-prize/:id",
  CLAIM_SUCCESS: "/thanks",
  LINK_EXPIRED: "/link-expired",
  ACTIVATE: "/activate/:id",
  ALREADY_ENTERED: "/already-entered",
};
